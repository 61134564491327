
import './styles/app.scss';
import jquery from 'jquery';
import ElectriumPanoClass from './js/ElectriumPanoClass';

class Loader {
    constructor() {
      this.amountLoaded = 0;
      this.totalAmount = 0;
      this.percentage = 0;
      this.element = jquery('.loader-holder');
      this.totalAmountElement = jquery(this.element).find('.totalAmount');
      this.amountLoadedElement = jquery(this.element).find('.amountLoaded');
      this.percentageLoadedElement = jquery(this.element).find('.percentageLoaded');
      this.loadingbar = jquery(this.element).find('.loadingbar');
    }

    showLoader() {
      this.element.css({
        display: 'block',
      });
    }

    hideLoader() {
      jquery(this.element).fadeOut('slow', () => {
        this.element.css({
          display: 'none',
        });
      });
    }

    addTotalLoaded(amount) {
      this.totalAmount = amount;
      jquery(this.totalAmountElement).html(this.totalAmount);
    }

    addAmountLoaded(amount) {
      this.amountLoaded = amount;
      jquery(this.amountLoadedElement).html(this.amountLoaded);
    }

    calculatePercentage() {
      this.percentage = Math.round((this.amountLoaded / this.totalAmount) * 100);
      jquery(this.loadingbar).css({
        width: this.percentage + '%',
      });
    }
  }

class App {
    static run(config) {
      const conf = { loader: new Loader(), config: config, viewer_data: window.viewer_data };
      new ElectriumPanoClass(conf);
    }
}

let app = App.run(electrium_config);