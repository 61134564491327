import * as PANOLENS from './panolens.module.js';
import * as THREE from 'three/build/three.js';
import lity from 'lity';
import $ from "jquery";
import slick from 'slick-carousel';
export default class ElectriumPanoClass {
  constructor(conf) {
    this.config = conf.config;
    this.loader = conf.loader;
    this.viewer_data = conf.viewer_data;
    this.panoramaMainImage = undefined;
    this.viewer_main = undefined;
    this.viewerSide = undefined;
    this.infospots = {};
    this.pano_viewers = [];
    this.hotspotPopups = [];
    this.view_position = [];

    this.momentumDampingFactor = conf.config.momentumDampingFactor;
    this.momentumScalingFactor = conf.config.momentumScalingFactor;
    this.momentumKeydownFactor = conf.config.momentumKeydownFactor;
    this.hotspot_elements = {}
    this.template = this.config.template;

    $('.container').addClass('launched');

    // load the first bg image and then set up the viewer
    this.loadImage(
      `${this.config.root}${this.viewer_data.sections[0].background_image}`,
      (data_url) => {
        this.targetIcon = data_url;
        this.setPanoramas();
        this.setControls();
        this.setHotspots();
        $(document).on('lity:open', (event, instance) => {
          $('#viewer-container').prepend(instance.element());
        });

        setTimeout(() => {
          this.loader.hideLoader();
        }, 500);
      },
      'image/png'
    );
  }

  setPanoramas() {
    this.viewer_main = new PANOLENS.Viewer(this.config.viewer_main);

    for (let i = 0; i < this.viewer_data.sections.length; i++) {
      const section = this.viewer_data.sections[i];
      const pano_viewer = new PANOLENS.ImagePanorama(section.background_image);
      const start_pos = (section.start_position) ? section.start_position : [];
      if (start_pos.length) {
        pano_viewer.addEventListener('enter-fade-start', () => {
          this.viewer_main.tweenControlCenter(new THREE.Vector3(
            start_pos[0],
            start_pos[1],
            start_pos[2]
          ), 1500);
        });
      }
      this.viewer_main.add(pano_viewer);
      this.pano_viewers.push(pano_viewer);
    }
  }

  setHotspots(){

    let i, j, k, m;

    for (i = 0; i < this.viewer_data.sections.length; i++) {

      // this.viewer_main.add(pano_viewer);
      const pano = this.pano_viewers[i];
      const { hotspots } = this.viewer_data.sections[i];

      const buildHotSpotInfoPanel = (hotspot_data) => {
        let markup = this.template;
        markup = markup.replace('{{header}}', hotspot_data.title)
        markup = markup.replace('{{images}}', hotspot_data.images.map((image) => {
          return `<img src="${this.config.products_path}${image}"/>`;
        }).join(''));
        markup = markup.replace('{{desc}}', hotspot_data.desc.map((desc) => {
          return `<li>${desc}</li>\n`;
        }).join(''));
        markup = markup.replace('{{link}}', hotspot_data.link);
        return markup;
      }

      if (hotspots) {
        for (m = 0; m < hotspots.length; m++) {
          (function (t, m) {
            const hotspot_data = hotspots[m];
            const hotspot = new PANOLENS.Infospot(300, t.config.product_icon, true);
            let panel = $(buildHotSpotInfoPanel(hotspot_data));
            t.hotspot_elements[`${hotspot_data.id}_${m}`] = panel[0];
            let element = $('<p></p>').addClass('electrium view-element').text('EXPLORE PRODUCT');
            hotspot.addHoverElement(element[0]);
            hotspot.position.set(hotspot_data.position[0], hotspot_data.position[1], hotspot_data.position[2]);
            hotspot.addEventListener('click', (e) => {
              hotspot.onDismiss();
              t.openHotspot(`${hotspot_data.id}_${m}`);
            });
            pano.add(hotspot);
          }(this, m))
        }
      }

      const { links } = this.viewer_data.sections[i];
      if (links) {
        for (m = 0; m < links.length; m++) {
          (function (t, m) {
            const link_data = links[m];
            const size = (link_data.size) ? link_data.size: 300;
            const link = new PANOLENS.Infospot(size, t.config.link_icon, true);
            let element = $('<p></p>').addClass('electrium view-element').text(`ENTER ${link_data.room}`);
            link.addHoverElement(element[0], link_data.size/10);
            link.position.set(link_data.position[0], link_data.position[1], link_data.position[2]);
            let panoTarget = t.pano_viewers[link_data.target];
            link.addEventListener('click', (e) => {
              link.onDismiss();
              t.viewer_main.setPanorama(panoTarget);
            });
            // link.temp_id = 'product'
            pano.add(link);
          }(this, m))
        }
      }
    }
  }

  positionView(startView) {
    let startView_position = new THREE.Vector3(
      startView[0],
      startView[1],
      startView[2]
    );
    pano_viewer.addEventListener('enter-fade-start', () => {
      this.viewer_main.tweenControlCenter(startView_position, 0);
    });
  }

  onFocus(hotspot) {
    const focus_parameters = {
      duration: 500,
      curve: 'Exponential',
      easing: 'In',
      iterative: false
    };
    hotspot.focus(focus_parameters.duration, TWEEN.Easing[focus_parameters.curve][focus_parameters.easing]);
  }



  openHotspot(id) {

    const elem = this.hotspot_elements[id];

    if (!elem) {
      return false;
    }

    const inst = lity(this.hotspot_elements[id]);
    let lity_element = inst.element();
    let location_images = $(lity_element).find('.location-images');
    if (!$(location_images).hasClass('slick-initialized')) {

      let img_length = $(location_images).find('img').length;

      if (img_length > 1) {
        let arrows = $('<div class="arrows"></div>');
        $(location_images).after(arrows);
        $(location_images).slick({
          appendArrows: arrows,
          adaptiveHeight: false,
          accessibility: false
        });

        arrows.prepend('<span class="view-finishes">View finishes</span>');
      }
    }
  }

  setControls() {
    // Camera Controls

    // Momentum
    this.viewer_main.OrbitControls.momentumDampingFactor = this.momentumDampingFactor || 0.75;
    this.viewer_main.OrbitControls.momentumScalingFactor = this.momentumScalingFactor || -0.01;
    this.viewer_main.OrbitControls.momentumKeydownFactor = this.momentumKeydownFactor || 10;

    // Fov
    this.viewer_main.OrbitControls.minFov = 50;
    this.viewer_main.OrbitControls.maxFov = 160;

    // Zoom
    this.viewer_main.OrbitControls.noZoom = true;

  }

  loadImage(src, callback) {
    var img = new Image();
    img.src = src;
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      callback(img);
    };
  }
}
